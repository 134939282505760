@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.form-field-density(-5);
@include mat.table-density(-5);
@include mat.list-density(-2);

:root {
  --primary: #000000;
  --secondary: #a06dab;
  --tertiary: #e94d55;
}

//*** HTML & BODY START ***
html,
body {
  height: 100%;
}

html {
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.87) !important;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.87) !important;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.6) !important;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.87) !important;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
//*** HTML & BODY END ***





//*** MAT TABLE START ***
//mat-table width of cells
.mat-sort-header-container {
  width: max-content !important;
}

//header padding
.company-detail-table .mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 10px !important;
}

//cell padding
.mdc-data-table__cell {
  padding: 0 10px !important;
}

th,
td {
  @apply text-gray-700;
}
//*** MAT TABLE END ***





//*** MAT TABLE PAGINATOR START ***
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 8px 0 !important;
}

.mat-mdc-paginator {
  display: unset !important;
}

.mat-mdc-paginator-container .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  height: 30px;
  display: flex;
  align-items: center;
}

.mat-mdc-paginator-range-label {
  margin: 0 8px 0 8px !important;
}

.mat-mdc-paginator-page-size {
  margin-bottom: 4px;
}

.mat-mdc-paginator-container {
  font-size: 12px !important;
}

@media (min-width: 476px) {
  .mat-mdc-paginator-page-size {
    margin-bottom: 0;
  }
}
//*** MAT TABLE PAGINATOR END ***





//*** INPUTFIELDS START ***
//inputfields padding left & right
.mdc-text-field {
  padding: 0 8px !important;
}

//inputfields label position
.mdc-notched-outline__leading {
  width: 4px !important;
}

[type='text']:focus,
input:where(:not([type])):focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

[type='text'], 
input:where(:not([type])), [
  type='email'], 
  [type='url'], 
  [type='password'], 
  [type='number'], 
  [type='date'], 
  [type='datetime-local'], 
  [type='month'], 
  [type='search'], 
  [type='tel'], 
  [type='time'], 
  [type='week'], 
  [multiple], 
  textarea, 
  select {
  border: 0 !important;
  padding: 0 !important;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
  z-index: 10000;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix > div {
  width: 100%;
}

.mat-form-field-normal,
.mat-form-field-normal .mat-mdc-text-field-wrapper,
.mat-form-field-normal .mat-mdc-form-field .mdc-text-field__input,
.mat-form-field-normal .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 26px !important;
}

.mat-mdc-form-field-infix {
  min-height: 26px !important;
}

.mat-form-field-normal .mdc-notched-outline__notch {
  display: flex !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  display: flex;
  padding: 0 !important;
}

//bg color of status-inputfield
.invoice-list-status-select .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-select-panel {
  width: auto !important;
  position: absolute !important;
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch {
  max-width: unset !important;
}

//click on url in readonly/disabled state
.mat-form-field-disabled .mdc-notched-outline,
.mdc-text-field--disabled {
  pointer-events: unset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background, 
.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  background-color: #0F658C !important;
  border-color: #0F658C !important;
}

.mdc-notched-outline__notch {
  border-right: none;
}
.mat-mdc-form-field {
  position: relative;
  margin-bottom: 13px;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  z-index: 10;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  display: inline-table;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input::placeholder {
  font-size: 12px !important;
}
//*** INPUTFIELDS END ***





//*** FILTER START ***
.mat-form-field-filter .mat-icon{
  font-size: 18px !important;
  padding: 0 !important;
  margin-top: 5px;
  color: #0F658C !important;
}
.mat-form-field-filter .mat-mdc-form-field-icon-suffix {
  position: relative;
  left: 5px;
}
//*** FILTER END ***





//*** TEXTAREA START ***
.mat-form-field-textarea-thin {
  height: 26px !important;
}

.mat-form-field-textarea-thin .mat-mdc-text-field-wrapper,
.mat-form-field-textarea-thin .mat-mdc-form-field .mdc-text-field__input,
.mat-form-field-textarea-thin .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 22px !important;
}

.mat-form-field-textarea-thin .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 3px !important;
}

.mat-form-field-textarea .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 85px !important;
}

.mat-form-field-textarea .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  top: 10px !important;
}

.mat-form-field-textarea-thin .mdc-notched-outline__notch  {
  display: flex !important;
}

.mat-form-field-textarea .mat-mdc-text-field-wrapper,
.mat-form-field-textarea .mat-mdc-form-field .mdc-text-field__input {
  height: 100px !important;
}
//*** TEXTAREA END ***





//*** INPUT DATEPICKER START ***
.mat-mdc-icon-button svg {
  width: 20px !important;
  height: 20px !important;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 26px !important;
  height: 26px !important;
}

mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  display: flex;
  justify-content: end;
  align-items: center;
}
//*** INPUT DATEPICKER END ***





//*** INPUTFIELDS ERROR START ***
.mat-mdc-form-field-subscript-wrapper {
  height: 16px !important;
  font-size: 12px !important;  
  position: absolute !important;
  right: 0 !important;
  width: 50px !important;
}

.mat-mdc-form-field-error {
  position: relative;
  top: -4px;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 4px 0 0 !important;
  left: unset !important;
  width: 50px !important;
  z-index: 1 !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
//*** INPUTFIELDS ERROR END ***





//*** BUTTONS START ***
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(220, 220, 220) !important;
  --mdc-protected-button-container-color: rgba(220, 220, 220) !important;
}

.mdc-fab--mini {
  width: 35px !important;
  height: 35px !important;
}
//*** BUTTONS END ***





//*** MAT TAB START ***
.mat-mdc-tab-body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mat-mdc-tab-body-wrapper {
  box-shadow: none !important;
}

.mat-mdc-tab-header {
  position: sticky !important;
  z-index: 10 !important;
}

.mat-mdc-tab.due .mdc-tab__text-label {
  color: #f87171;
}

.mat-mdc-tab-body-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.mat-mdc-tab-body-wrapper {
  box-shadow:
    0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
}
//*** MAT TAB END ***





//*** DRAG & DROP START ***
.draggable-element .drag-handle {
  cursor: grab;
}

.draggable-element.cdk-drag-preview .drag-handle {
  pointer-events: auto;
  cursor: grabbing;
}
//*** DRAG & DROP END ***





//*** MAT EXPANSION PANEL START ***
.mat-expansion-indicator::after {
  border-color: #3f51b5;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

//background color of accordion panels
.invoice-accordion .mat-expansion-panel, .mat-mdc-table .mat-mdc-header-row  {
  background: #F8FAFC !important;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}
//*** MAT EXPANSION PANEL END ***





//*** MAT OPTION START ***
//mat option styling & remove checked style
.mat-mdc-option {
  min-height: 30px !important;
  background-color: unset !important;
  &:hover {
    background-color: #F6F6F6 !important;
  }
}
//mat option remove checked style
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

//mat option remove checked style
.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none !important;
}
//*** MAT OPTION END ***





//*** NAVIGATION START ***
.navigation-list .mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 16px !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 1 !important;
}
//*** NAVIGATION END ***





// *** OTHER START ***
/* default .loading styles, .loading should be invisible, opacity: 0, z-index: -1 */
.loading {
  display: none;
  opacity: 0;
  height: 0;
  transition: opacity 0.8s ease-in-out;
}
/* .loading screen is visible when app is not bootstrapped yet, my-app is empty */
financehub-bds421-com-root:empty + .loading {
  display: flex;
  opacity: 1;
  height: 100vh;
  z-index: 100;
  color: white;
}

//mat-card: full width
.mat-mdc-card-header-text {
  width: 100% !important;
}

.hide-field .mat-mdc-form-field-subscript-wrapper{
  display: none !important;
}

mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  height: 24px !important;
  width: 24px !important;
  padding: 0 !important;
}

.mat-toolbar {
  z-index: 50 !important;
}
// *** OTHER END ***
