@import '../node_modules/@angular/material/theming';
@include mat-core();

$my-primary: (
  50: #0F658C,
  100: #0F658C,
  200: #0F658C,
  300: #0F658C,
  400: #0F658C,
  500: #0F658C,
  600: #0F658C,
  700: #0F658C,
  800: #0F658C,
  900: #0F658C,
  A100: #0F658C,
  A200: #0F658C,
  A400: #0F658C,
  A700: #0F658C,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$my-palette-primary: mat-palette($my-primary);
$my-accent: mat-palette($mat-light-green, 800);

$my-theme: mat-light-theme($my-palette-primary, $my-accent);

@include angular-material-theme($my-theme);

.mat-mdc-raised-button:not(:disabled) {
  color: white !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  color: white !important;
}

//#0F658C - primary-color
